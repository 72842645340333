var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"1"}},[_c('ImgView',{attrs:{"fluid":"","src":_vm.imgUrl+_vm.tableItems[0].images[0]}})],1),_c('CCol',{attrs:{"md":"11"}},[_c('CDataTable',{attrs:{"border":"","hover":"","size":"sm","addTableClasses":"m-0","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"identifier",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.identifier))])])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.name))])])}},{key:"capacity",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(_vm._f("personCapacity")(item.min,item.max)))])])}},{key:"inventory",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.inventory))])])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center p-0"},[_c('CSwitch',{staticClass:"mt-1 swc sw-2",attrs:{"color":"info","variant":"opposite","labelOn":_vm.$t("common.active"),"labelOff":_vm.$t("common.inActive"),"checked":_vm._f("masterStatus")(item.status)},on:{"update:checked":function($event){return _vm.onStatusChanged(item)}}})],1)}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"p-0 text-center"},[_c('CButton',{staticClass:"mt-1 px-3",attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditClicked(item.id)}}},[_c('i',{staticClass:"icon cil-pencil mr-1"}),_vm._v(" "+_vm._s(_vm.$t("common.edit")))])],1)]}}])})],1)],1),_c('SuccessModal',{attrs:{"successModal":_vm.successModal},on:{"close":function($event){_vm.successModal = $event}}}),_c('ErrorModal',{attrs:{"errorModal":_vm.errorModal,"errorMessage":_vm.errorMessage},on:{"close":function($event){_vm.errorModal = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }