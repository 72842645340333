<template>
  <!-- 
    画面: 部屋タイプ一覧カード
    用途: 部屋タイプ一覧の1件のデータを表示する
   -->
  <div>
    <CRow>
      <CCol md="1">
        <ImgView
          fluid
          :src="imgUrl+tableItems[0].images[0]"
        />
      </CCol>
      <CCol md="11">
        <!-- #region Table -->
        <CDataTable
          border
          hover
          size="sm"
          addTableClasses="m-0"
          :fields="tableFields"
          :items="tableItems"
        >

          <!-- #region 識別子 -->
          <td
            slot="identifier"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">{{ item.identifier }}</span>
          </td>
          <!-- #endregion 識別子 -->

          <!-- #region 名称 -->
          <td
            slot="name"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">{{ item.name }}</span>
          </td>
          <!-- #endregion 名称 -->

          <!-- #region 定員 -->
          <td
            slot="capacity"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.min | personCapacity(item.max) }}</span>
          </td>
          <!-- #endregion 定員 -->

          <!-- #region 基準在庫数 -->
          <td
            slot="inventory"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.inventory }}</span>
          </td>
          <!-- #endregion 基準在庫数 -->

          <!-- #region 表示／非表示 -->
          <td
            slot="status"
            slot-scope="{ item }"
            class="text-center p-0"
          >
            <CSwitch
              class="mt-1 swc sw-2"
              color="info"
              variant="opposite"
              :labelOn='$t("common.active")'
              :labelOff='$t("common.inActive")'
              :checked="item.status | masterStatus"
              @update:checked="onStatusChanged(item)"
            />
          </td>
          <!-- #endregion 表示／非表示 -->

          <!-- #region アクション -->
          <template #action="{ item }">
            <td class="p-0 text-center">
              <CButton
                class="mt-1 px-3"
                color="info"
                square
                size="sm"
                @click="onEditClicked(item.id)"
              ><i class="icon cil-pencil mr-1"></i> {{ $t("common.edit") }}</CButton>
            </td>
          </template>
          <!-- #endregion アクション -->

        </CDataTable>
        <!-- #endregion Table -->

      </CCol>
    </CRow>

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import axios from "axios";
import i18n from "@/i18n";
import STATUS from "@/mixins/property";
import config from "@/config"

export default {
  name: "RoomTypeListCard",

  mixins: [STATUS],

  props: {
    /** カード1件分データ */
    tableItems: {
      type: Array,
      required: true,
      // default: {}
    },

    reqPutStatus: {
      type: Function,
      required: true,
    }
  },

  data() {
    return {
      //#region Flag
      successModal: false,
      errorModal: false,
      errorMessage: "",
      imgUrl: config.IMAGE_URL,
      //#endregion Flag


      //#region Table
      tableFields: [
        {
          key: "identifier",
          label: `${i18n.t("roomTypeListPage.identifier")}`,
          _classes: "text-center bg-light",
          _style: "width:120px",
        },
        {
          key: "name",
          label: `${i18n.t("common.name")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "capacity",
          label: `${i18n.t("roomTypeListPage.capacity")}`,
          _classes: "text-center bg-light",
          _style: "width:120px",
        },
        {
          key: "inventory",
          label: `${i18n.t("roomTypeListPage.baseInventory")}`,
          _classes: "text-center bg-light",
          _style: "width:120px",
        },
        {
          key: "status",
          label: `${i18n.t("common.switchActive")}`,
          _classes: "text-center bg-light",
          _style: "width:120px",
        },
        {
          key: "action",
          label: `${i18n.t("common.action")}`,
          _classes: "text-center bg-light",
          _style: "width:120px",
        },
      ],
      //#endregion Table
    };
  },

  methods: {
    onStatusChanged(item) {
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    onEditClicked() {
      const roomTypeId = this.tableItems[0].id;
      this.$router.push({
        path: `/operator/roomType/${roomTypeId}/edit`,
        // path: `/admin/roomType/${roomTypeId}/edit`,
      });
    },
    //#endregion Event


    //#region Method
    toggleStatus(status) {
      return status == this.STATUS.VALID ? this.STATUS.INVALID : this.STATUS.VALID;
    },
    //#endregion Method
  },

};
</script>
