<template>
  <!-- 
    画面: 部屋タイプ一覧
    用途: 部屋タイプ一覧を参照する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-4">{{ $t("roomTypeListPage.pageTitle") }}</strong>
            <CButton
              color="info"
              @click="onCreateClicked()"
            >
              {{ $t("common.create") }}
            </CButton>
          </CCardHeader>
          <CCardBody>
            <RoomTypeListTable />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

  </div>
</template>

<script>
import RoomTypeListTable from "./components/ListTable";

export default {

  name: "RoomTypeList",

  components: {
    RoomTypeListTable
  },

  methods: {
    //#region Event
    onCreateClicked() {
      this.$router.push({
        path: `/operator/roomType/create`
        // path: `/admin/roomType/create` // NOTE: Vue開発用
      });
    },
    //#endregion Event
  },

}
</script>
