<template>
  <!-- 
    画面: 部屋タイプ一覧テーブル
    用途: 部屋タイプ一覧カードを一覧表示する
   -->
  <div>
    <Draggable
      class="list-group"
      v-model="tableItems"
      @end="onSortEnd"
      v-bind="dragOptions"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >

        <template v-for="tableItem in tableItems">
          <RoomTypeListCard
            :key="tableItem.id"
            :tableItems="[tableItem]"
            :reqPutStatus="reqPutStatus"
            class="border-white border rounded p-2 mb-4 with-drag-button "
          />
        </template>

      </transition-group>
    </Draggable>

    <!-- #region Alert -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      表示可能な部屋タイプはありません。
    </CAlert>
    <!-- #endregion Alert -->

  </div>
</template>

<script>
import axios from "axios";
import RoomTypeListCard from "./ListCard";

export default {
  name: "RoomTypeListTable",

  components: {
    RoomTypeListCard,
  },

  props: {},

  data() {
    return {
      //#region Flag
      loading: false,
      //#endregion Flag

      tableItems: [],
      order: [],
      response: [],
      errorMessage: "",
    };
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      return !this.loading && !this.notFound;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return !this.response || this.response.length < 1;
    },

    /** generate class for dragg list */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  methods: {
    //#region Event
    onSortEnd() {
      // 一覧表の表示順IDを昇順の値にする
      this.tableItems.forEach((a, i) => a.displayOrder = this.order[i]);
      this.reqPutDisplayOrder();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      const url = `/rest/hotel/${this.uid}/room`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data.rooms;
          this.response = datas;
          this.pourTable(datas);
          this.createDisplayOrderIdAscList(datas);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = `${err.name} : ${err.message}`;
          this.loading = false;
        });
    },

    reqPutDisplayOrder() {
      const url = `/rest/hotel/${this.uid}/room/order`;

      let list = [];
      this.tableItems.forEach((a) => list.push({ id: a.id, displayOrder: a.displayOrder }));
      const body = { list };

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.reqGet();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },

    reqPutStatus(item) {
      const url = `/rest/hotel/${this.uid}/room/${item.id}/status`;
      const body = { status: item.status };

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.reqGet();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = err;
          console.log(err);
        });
    },
    //#endregion Request


    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((data) => {
          this.tableItems.push({
            id: data.id,
            identifier: data.identifier,
            name: data.name,
            min: data.min,
            max: data.max,
            inventory: data.inventory,
            images: data.images,
            status: data.status,
            displayOrder: data.displayOrder,
          });
        });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(item) {
      this.order = [];
      item.forEach((a) => this.order.push(a.id));
      this.order.sort((a, b) => a - b);
      console.log()
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
